import { getErrorMessage, logMessage,onError } from '../utils/helpers';
import { HistoryProvider, } from './history-provider';
import {subscribeOnOdinStream,unsubscribe,closeConnection,openWebSocketConnection,closeConnectionTemporarily} from './streaming_provider';
import { customIndicator } from "../components/TVChartContainer/custom_studies";
import { SlowBuffer } from 'buffer';
function extractField(data, field, arrayIndex) {
    const value = data[field];
    return Array.isArray(value) ? value[arrayIndex] : value;
}
/**
 * This class implements interaction with UDF-compatible datafeed.
 * See UDF protocol reference at https://github.com/tradingview/charting_library/wiki/UDF
 */

const LastBarsCache = new Map();

export class UDFCompatibleDatafeedBase {
    constructor(odin_url,inhouse_url, requester,odinRequester, inHouseRequester) {
        this._configuration = defaultConfiguration();
        this._odinUrl = inhouse_url;
        this._inHouseUrl = inhouse_url + "aux/";
        this._requester = requester;
        this._odinRequester= odinRequester;
        this._inHouseRequester = inHouseRequester; 
        this._historyProvider = new HistoryProvider(inhouse_url, this._requester);
        this._symbolToTokenMap = {}
        this._tokenToSymbolMap = {}
        this._symbolInfoMap = {}
        this._quote_input=[]
        this._quote_symbol_info={}
        this.supported_resolutions = []
        this._fill_resolutions()
        this._setupWithConfiguration({
            "exchanges": [
                {
                    "value": "All Exchanges",
                    "name": "All Exchanges",
                    // "desc": ""
                },
                {
                    "value": "NSE",
                    "name": "NSE",
                    // "desc": "National Stock Exchange "
                },
                {
                    "value": "BSE",
                    "name": "BSE",
                    // "desc": "National Stock Exchange "
                },
                {
                    "value": "MCX",
                    "name": "MCX",
                    // "desc": "Multi Commodities Exchange"
                }
            ],
            // "exchanges": ["NSE","MCX"],
            "symbols_types": [{"name":"stocks", "value": "stocks"}],
            "supported_resolutions": [
                "5s",
                "15s",
                "30s",
                "1",
                "2",
                "3",
                "4",
                "5",
                "10",
                "15",
                "30",
                "45",
                "60",
                "120",
                "240",
                // "3",
                // "5",
                // "15",
                // "60",
                // "120",
                // "240",
                "1D",
                "1W",
                "1M"
            ],
            "supports_search": true,
            "supports_group_request": false,
            "supports_marks": false,
            "supports_timescale_marks": true,
            "supports_time": true
        });
    }

    _fill_resolutions(){
        this.supported_resolutions.push("5S","15S","30S")
        for (let index = 1; index < 1441; index++) {
           this.supported_resolutions.push(index.toString())
        }

        for (let index = 1; index < 366; index++) {
            this.supported_resolutions.push(index.toString()+"D")
        }

        for (let index = 1; index < 13; index++) {
            this.supported_resolutions.push(index.toString()+"M")
        }
        for (let index = 1; index < 53; index++) {
            this.supported_resolutions.push(index.toString()+"W")
        }
    }

    OnReadyCallback(callback){
        setTimeout(() => callback(defaultConfiguration()));
    }
    onReady(callback) {
		setTimeout(() => callback(defaultConfiguration()));
	}
    connectFeed(ocToken,clientCode){
            openWebSocketConnection(ocToken,clientCode);
    }
    closeFeed(){
        closeConnection();
    }
    closeFeedTemporarily(){
        closeConnectionTemporarily();
    }

    gethighLow(symbol){
        return this.resolveSymbol(symbol, (symbolInfo)=>{
            let result = response.filter(res => res.token != undefined)
            return this._inHouseRequester.sendRequest(this._inHouseUrl, 'highLow', undefined,{token:symbolInfo.odin_token.toString(), market_segment_id:symbolInfo.odin_market_segment_id  },"GET").then((response)=>{
                return Promise.resolve(response)
            })
        })
        
    }
    //This api is being used to show the news on tv terminal
    getNews(symbol,onDataCallback){ 
        for (const key in this._symbolInfoMap) {
            var values=key.split(":")
            if (values.length>1 && values[1]==symbol) {
                symbol = key;
              break;
            }
          }
        this._inHouseRequester.sendRequest(this._inHouseUrl, 'newsBySymbol/'+symbol, undefined,undefined,"GET")
        .then((res)=>{
            var body = res.response.map(item => {
                return {
                    title: item.title, 
                    published: Date.parse(item.pubDate),
                    source: item.source,
                    shortDescription:'',
                    link: item.newsLink,
                    fullDescription: ''
                }
                
            })
        
            onDataCallback({title: "News", newsItems: body});
        }).catch((e)=>console.error("news api request error",e))
    }
    
    
    _exchangeMap(segment){ 
        const dict = {
            "2":"NSE_FO",
            "13":"NSE_CD",
            "1":"NSE_EQ",
            "5":"MCX_FO",
            "4":"BSE_FO",
            "3":"BSE_EQ"
        }
        return dict[segment]
    }

//This is being used to get the quotes of symbols which all are in watchlist and on screen chart like ltp,ch,chp,oi etc. used by tradingview library
    async getQuotes(symbols, onDataCallback, onErrorCallback){
        var input_string = ""
        var quoteSymbolMap= {}
        if (symbols == undefined || symbols.length == 0){
            onErrorCallback("No symbols found")
        }
        for (let index = 0; index < symbols.length; index++) {
            const symbol = symbols[index];
            await this.resolveSymbol(symbol).then((symbolInfo)=>{
                // if (this._exchangeMap(symbolInfo.odin_market_segment_id) == undefined){   
                //     console.log(symbol,symbolInfo)
                // }
                quoteSymbolMap[this._exchangeMap(symbolInfo.odin_market_segment_id)+"-"+symbolInfo.odin_token] = symbolInfo
                input_string = input_string +  "q="+this._exchangeMap(symbolInfo.odin_market_segment_id)+"-"+symbolInfo.odin_token+"&"
            })
        }
        input_string = input_string +"mode=full"
        this._inHouseRequester.getQuotes(this._inHouseUrl,input_string)
        .then((response)=>{
            var quotes = []
            if (response.data == undefined){
                throw new Error("quotes api request error "+JSON.stringify(symbols)+" "+input_string +  JSON.stringify(response)) 
            }
            var keys = Object.keys(response.data)
            for (let index = 0; index < keys.length; index++) {
                const item = response.data[keys[index]];
                const bidPrice = item.depth.buy.find(obj => obj.hasOwnProperty("price"))?.["price"]
                const askPrice = item.depth.sell.find(obj => obj.hasOwnProperty("price"))?.["price"]
                const symbolInfo = quoteSymbolMap[keys[index]]
                let body = {
                    ch: (item.last_trade_price - item.close_price),
                    chp: ((item.last_trade_price - item.close_price)/item.close_price * 100),
                    short_name: symbolInfo.odin_symbol,
                    exchange: symbolInfo.odin_exchange,
                    description: symbolInfo.description,
                    lp: item.last_trade_price, 
                    open_price: item.open_price, 
                    high_price: item.high_price, 
                    low_price: item.low_price, 
                    prev_close_price: item.close_price, 
                    volume: item.volume?item.volume:0,  
                    ask:askPrice?askPrice:0,
                    bid:bidPrice?bidPrice:0,
                    original_name:symbolInfo.name,
                    dpr:item.dpr_low && item.dpr_high?item.dpr_low/100+"-"+item.dpr_high/100:"0.00-0.00",
                    placeOrder:true
                }
                let quote = {
                    n:symbolInfo.full_name,
                    s:"ok",
                    status:"ok",
                    symbolname:symbolInfo.full_name,
                    v:body
                }
                quotes.push(quote)
            }
            onDataCallback(quotes)
        }).catch((e)=>{
            console.error("quotes api request error",e)
            onErrorCallback(e)
        })
    }

    
    //Subscribe the quotes like ltp,oi,change,change% etc
    subscribeTtQuotes(symbols, fastSymbols, onRealtimeCallback, listenerGuid) {
        if (fastSymbols == null ){
            fastSymbols = symbols
        }
        let allSym = [... new Set(fastSymbols.concat(symbols))]

        var symbolInfos = {}
        allSym.forEach(symbol => {
            this.resolveSymbol(symbol,(symbolInfo)=>{
                symbolInfos[symbol] = symbolInfo
                if(Object.keys(symbolInfos).length == allSym.length){
                    subscribeOnOdinStream(Object.values(symbolInfos),
                        undefined,
                        onRealtimeCallback,
                        listenerGuid,
                        undefined,
                        undefined,'tt-quotes')
                }
            })
        });
    }

    //Subscribe the quotes like ltp,oi,change,change% etc used by tradingview library
    subscribeQuotes(symbols, fastSymbols, onRealtimeCallback, listenerGuid) {
        if (fastSymbols == null ){
            fastSymbols = symbols
        }
        let allSym = [... new Set(fastSymbols.concat(symbols))]
        var symbolInfos = {}
        symbols.forEach(symbol => {
            this.resolveSymbol(symbol,(symbolInfo)=>{
                symbolInfos[symbol] = symbolInfo
                if(Object.keys(symbolInfos).length == symbols.length){
                    subscribeOnOdinStream(Object.values(symbolInfos),
                        undefined,
                        onRealtimeCallback,
                        listenerGuid,
                        undefined,
                        undefined,'quotes')
                }
            })
        });
            
        
    }
    //Subscribe from scrip tokens for quotes like ltp,oi,change,change% etc
    async subcscribeQuotesFromTokens(tokens,onRealtimeCallback,listenerGuid ){
        // var promises = tokens.map((token)=> {
        //     return this.tokenInfo(token).then((info)=>{
        //         return Promise.resolve({
        //             symbol: info.symbol,
        //             odin_token: info.token, 
        //             odin_market_segment_id: info.market_segment_id
        //         })
        //     })     
            
        // })
        // Promise.all(promises).then((response)=>{
        //     subscribeOnOdinStream(response,
        //         undefined,
        //         onRealtimeCallback,
        //         listenerGuid,
        //         undefined,
        //         undefined,'quotes')
        // })
        const promises = await Promise.all(tokens.map(async (token) => {
            const info = await this.tokenInfo(token);
            return {
              symbol: info.symbol,
              odin_token: info.token,
              odin_market_segment_id: info.market_segment_id
            };
          }));
          
          subscribeOnOdinStream(promises, undefined, onRealtimeCallback, listenerGuid, undefined, undefined, 'quotes');
            
    }
    //To unsubscribe the quotes from odin
    unsubscribeQuotes(listenerGuid) {
        unsubscribe(listenerGuid)
    }

    getMarks(symbolInfo, from, to, onDataCallback, resolution) {
        if (!this._configuration.supports_marks) {
            return;
        }
        const requestParams = {
            symbol: symbolInfo.ticker || '',
            from: from,
            to: to,
            resolution: resolution,
            market_segment_id: symbolInfo.odin_market_segment_id, 
            token: symbolInfo.odin_token
        };
        this._inHouseSend('timescaleMarks', requestParams,{},"GET")
            .then((response) => {
                if (response.marks){
                    onDataCallback(response.marks)
                }else{
                    onDataCallback([])
                }
        })
            .catch((error) => {
            logMessage(`UdfCompatibleDatafeed: Request marks failed: ${getErrorMessage(error)}`);
            onDataCallback([]);
        });
    }
    getTimescaleMarks(symbolInfo, from, to, onDataCallback, resolution) {
        if (!this._configuration.supports_timescale_marks) {
            return;
        }
        const requestParams = {
            symbol: symbolInfo.symbol || '',
            from: from,
            to: to,
            resolution: resolution,
            market_segment_id: symbolInfo.odin_market_segment_id, 
            token: symbolInfo.odin_token
        };
        this._inHouseSend('timescaleMarks', requestParams,{},"GET")
            .then((response) => {
                if (response.marks){
                    onDataCallback(response.marks)
                }else{
                    onDataCallback([])
                }
        })
            .catch((error) => {
            logMessage(`UdfCompatibleDatafeed: Request timescale marks failed: ${getErrorMessage(error)}`);
            onDataCallback([]);
        });
    }
    getServerTime(callback) {
        if (!this._configuration.supports_time) {
            return;
        }
        this._send('time')
            .then((response) => {
            const time = parseInt(response);
            if (!isNaN(time)) {
                callback(time);
            }
        })
            .catch((error) => {
            logMessage(`UdfCompatibleDatafeed: Fail to load server time, error=${getErrorMessage(error)}`);
        });
    }

    //Search the symbols based on userInput using odinApi
    searchSymbols(userInput, exchange, symbolType, onResult) {
        if (this._configuration.supports_search) {
            var final = 23
            switch(exchange){
                case "NSE": {
                    switch(symbolType){
                        case 5: {
                            final = 3
                            break; 
                        }
                        case 4: {
                            final = 1
                            break; 
                        }
                        case 3: {
                            final = 2
                            break; 
                        }
                        case 2: {
                            final = 2
                            break; 
                        }
                        case 1: { 
                            final  =1 
                            break; 
                        }
                    }
                    break; 
                }
                case "BSE": {
                    switch(symbolType){
                        case 5: {
                            final = 12
                            break; 
                        }
                        case 4: {
                            final = 8
                            break; 
                        }
                        case 3: {
                            final = 4
                            break; 
                        }
                        case 2: {
                            final = 4
                            break; 
                        }
                        case 1: { 
                            final  =8 
                            break; 
                        }
                        default: {
                            final = 12
                        }
                    }
                    break; 
                }
                case "MCX": {
                    final = 16 
                    break;
                }
                case "All Exchanges": {
                    switch(symbolType){
                        case 6: {
                            final = 9
                            break; 
                        }
                        case 5: {
                            final = 23
                            break; 
                        }
                        case 4: {
                            final = 9
                            break; 
                        }
                        case 3: {
                            final = 22
                            break; 
                        }
                        case 2: {
                            final = 22
                            break; 
                
                        }
                        case 1: { 
                            final  = 9
                            break; 
                        }
                    }
                    break; 
                }
            }
            ((userInput)=>{
                this._odinSend('non-trading/getScripLookUp/'+userInput.toUpperCase()+'/'+final.toString())
                .then((response) => {
                
                if (!response.status) {
                    logMessage(`UdfCompatibleDatafeed: search symbols error`);
                    onResult([]);
                    return;
                }
                response.result.hits.hits.forEach(obj => this._parseForSearch(obj, exchange, symbolType) )
                response.result.hits.hits = response.result.hits.hits.filter(e => e["full_name"] != undefined)
                onResult(response.result.hits.hits);
                })
                    .catch((reason) => {
                    logMessage(`UdfCompatibleDatafeed: Search symbols for '${userInput}' failed. Error=${getErrorMessage(reason)}`);
                    onResult([]);
                });
            })(userInput);
            
        }
    }

    //Get the complete details of symbol
    resolveSymbol(symbolName, onResolve, onError, extension) {
        logMessage('UdfCompatibleDatafeed: Resolve requested');
        const currencyCode = extension && extension.currencyCode;
        const unitId = extension && extension.unitId;
        const resolveRequestStartTime = Date.now();
        function onResultReady(symbolInfo, supported_resolutions) {
            logMessage(`UdfCompatibleDatafeed: Symbol resolved: ${Date.now() - resolveRequestStartTime}ms`);
            if (onResolve != undefined && onResolve != null && typeof onResolve == 'function'){
                symbolInfo.has_seconds = true
                symbolInfo.supported_resolutions = supported_resolutions
                symbolInfo.seconds_multipliers = ["1"]
                symbolInfo.has_ticks= true
                onResolve(symbolInfo);
            }
        }
        const params = {
            symbol: symbolName,
        };
        if (!this._configuration.supports_group_request) {
            if (this._symbolInfoMap[symbolName]){
                setTimeout(() => onResultReady(this._symbolInfoMap[symbolName],this.supported_resolutions));
                return Promise.resolve(this._symbolInfoMap[symbolName])
            }
            return this._inHouseSend('symbol', params).then((response)=>{
                if (response.s) {
                    logMessage(`UdfCompatibleDatafeed: Symbol resolved error: Unknown symbol`);
                    onError('unknown_symbol');
                    return Promise.reject('unknown_symbol')
                }
                else {
                    if (response.odin_market_segment_id == 8 || response.odin_market_segment_id =="8"){
                        response.odin_market_segment_id = 3
                    }
                    this._symbolInfoMap[symbolName] = response
                    onResultReady(response,this.supported_resolutions);
                    return Promise.resolve(response)
                }
            }).catch((reason) => {
                logMessage(`UdfCompatibleDatafeed: Symbol resolved error: ${getErrorMessage(reason)}`);
                return Promise.reject(reason)
            })
        }
    }

    //This returns the OHLCV of every candle i.e history
    getBars(symbolInfo, resolution, periodParams, onResult, onError) {
        const getBarsRequestStartTime = Date.now();
        logMessage(`UdfCompatibleDatafeed: Get Bars started: ${symbolInfo.symbol}, ${resolution}, ${JSON.stringify(periodParams)}`);
        customIndicator.getIndicatorValues(symbolInfo).then((d)=>{
            this._historyProvider.getBars(symbolInfo, resolution, periodParams).then((result)=>{
                logMessage('UdfCompatibleDatafeed:' +' '+resolution +' ' + JSON.stringify(periodParams));
                if(result.bars.length){
                    if(periodParams.firstDataRequest){
                        LastBarsCache.set(symbolInfo.full_name, {
                            ...result.bars[result.bars.length - 1],
                        });
                    }
                    logMessage(`UdfCompatibleDatafeed: Get Bars completed: ${symbolInfo.symbol}, ${resolution}, ${JSON.stringify(periodParams)},${JSON.stringify(result.bars)} : ${Date.now() - getBarsRequestStartTime}ms`);
                    onResult(result.bars, { noData: false });
                }else{
                    logMessage(`UdfCompatibleDatafeed: Get Bars completed no data: ${symbolInfo.symbol}, ${resolution}, ${JSON.stringify(periodParams)}: ${Date.now() - getBarsRequestStartTime}ms`);
                    onResult(result.bars, { noData: true, nextTime: result.meta.nextTime });
                }
            }).catch(onError);
        })
        
    }

    //Subscribe the bar to get data of candles in real time 
    subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
        logMessage(`UdfCompatibleDatafeed: Subscribe Bars called ${symbolInfo}, ${resolution}, ${subscriberUID}`);
        subscribeOnOdinStream([symbolInfo],
            resolution,
            onRealtimeCallback,
            subscriberUID,
            onResetCacheNeededCallback,
            LastBarsCache.get(symbolInfo.full_name),'bar')
    }
    //Unsubscribe the bar
    unsubscribeBars(listenerGuid) {
        logMessage(`UdfCompatibleDatafeed: Unsubscribe Bars called ${listenerGuid}`);
        unsubscribe(listenerGuid)
    }

    //Subscribe depth to get the top 5 bids and asks of any symbol 
    subscribeDepth(symbol,callback){
        logMessage(`UdfCompatibleDatafeed: Subscribe Depth called ${symbol}`);
        var subscriberUID = `DOM-${symbol}-${Date.now()}`
        this.resolveSymbol(symbol, (response)=>{
            subscribeOnOdinStream([response],undefined,callback,subscriberUID,undefined,undefined,'depth')
        })
        return subscriberUID
    }

    //Unsubscribe the depth
    unsubscribeDepth(subscriberUid){
        logMessage(`UdfCompatibleDatafeed: Unsubscribe Depth called ${subscriberUid}`);
        unsubscribe(subscriberUid)
    }

    getWatchLists(){
        const getWatchListsStartTime = Date.now();
        logMessage(`UdfCompatibleDatafeed: Get Watchlists called`);
        this._inHouseRequester.sendRequest(this._inHouseUrl, 'watchlist/stocks', body,params, method="GET")
        logMessage(`UdfCompatibleDatafeed: Get Watchlists finished ${Date.now - getWatchListsStartTime}ms`);
    }
    //Get the symbolInfo from token
    async tokenInfo(token) {
        return this._getSymbolFromTokens(token);
      }


    _send(urlPath, params) {
        return this._requester.sendRequest(this._inHouseUrl, urlPath, params);
    }


    //Call the odin apis
    _odinSend(urlPath, body, method){
        return this._odinRequester.sendRequest(this._odinUrl,urlPath, body, method);
    }


    //Call the flow-backend apis
    _inHouseSend(urlPath, params, body = {}, method){
        return this._inHouseRequester.sendRequest(this._inHouseUrl, urlPath,body,params, method)
    }


    //Set the configuration of scrips like its type,resolution etc.
    _setupWithConfiguration(configurationData) {
        this._configuration = configurationData;
        if (!configurationData.exchanges) {
            configurationData.exchanges = [];
        }
        if (!configurationData.supports_search && !configurationData.supports_group_request) {
            throw new Error('Unsupported datafeed configuration. Must either support search, or support group request');
        }
        if (configurationData.supports_group_request || !configurationData.supports_search) {
            
        }
        logMessage(`UdfCompatibleDatafeed: Initialized with ${JSON.stringify(configurationData)}`);
    }


    _parseForSearch(obj,exchange, symbolType){
        if (obj._source.nMarketSegmentId == 8){
            obj._source.nMarketSegmentId =3
        }
        switch (exchange) {
            case 'NSE':
            case 'BSE':
            case 'All Exchanges':
              switch (symbolType) {
                case 4:
                  if (obj._source.nIsIndex !== 1) {
                    return;
                  }
                  break;
                case 1:
                  if (obj._source.sInstrumentName !== 'EQUITIES' || obj._source.nIsIndex === 1) {
                    return;
                  }
                  break;
                case 2:
                  if (
                    obj._source.sInstrumentName !== 'OPTFUT' &&
                    obj._source.sInstrumentName !== 'OPTCUR' &&
                    obj._source.sInstrumentName !== 'OPTIDX' &&
                    obj._source.sInstrumentName !== 'OPTSTK'
                  ) {
                    return;
                  }
                  break;
                case 3:
                  if (
                    obj._source.sInstrumentName !== 'FUTCOM' &&
                    obj._source.sInstrumentName !== 'FUTSTK' &&
                    obj._source.sInstrumentName !== 'FUTIDX' &&
                    obj._source.sInstrumentName !== 'FUTCUR'
                  ) {
                    return;
                  }
                  break;
              }
              break;
            case 'MCX':
              switch (symbolType) {
                case 2:
                  if (obj._source.sInstrumentName !== 'OPTFUT') {
                    return;
                  }
                  break;
                case 3:
                  if (obj._source.sInstrumentName !== 'FUTCOM') {
                    return;
                  }
                  break;
                case 1:
                case 4:
                  return;
              }
                  break;
            default:
              return;
          }
        // if(exchange == 'NSE' || exchange == 'All Exchanges'){
        //     if(symbolType == 4){
        //         if(obj._source.nIsIndex != 1){
        //             return
        //         }
        //     }
        //     else if(symbolType == 1){
        //         if (obj._source.sInstrumentName != 'EQUITIES' || obj._source.nIsIndex == 1){
        //             return
        //         }
        //     }else if(symbolType ==2){
        //         if(obj._source.sInstrumentName != 'OPTFUT'  && obj._source.sInstrumentName != 'OPTCUR' && obj._source.sInstrumentName != 'OPTIDX' && obj._source.sInstrumentName != 'OPTSTK'){
        //             return
        //         }
        //     }else if(symbolType == 3){
        //         if(obj._source.sInstrumentName != 'FUTCOM' && obj._source.sInstrumentName != 'FUTSTK' && obj._source.sInstrumentName != 'FUTIDX' && obj._source.sInstrumentName != 'FUTCUR' ){
        //             return
        //         }
        //     }
        // }else if(exchange == "MCX"){
        //     if(symbolType == 2 ){
        //         if(obj._source.sInstrumentName != 'OPTFUT'){
        //             return
        //         }
        //     }else if(symbolType == 3){
        //         if(obj._source.sInstrumentName != 'FUTCOM'){
        //             return
        //         }
        //     }else if(symbolType == 1 || symbolType == 4){
        //         return 
        //     }
        // }else{
        //     return
        // }
        obj["full_name"] = obj._source.sSecurityDesc
        obj["exchange"]=obj._source.sExchange
        
        obj["data_status"] = "streaming"

        switch (obj._source.sExchange){
        case "NSE": 
            obj["exchange_logo"] = "https://static.rupeezy.in/stock_images/svgs/NSE.svg"
            break; 
        case "BSE": 
            obj["exchange_logo"] = "https://static.rupeezy.in/stock_images/svgs/BSE.svg"
            break; 
        case "MCX": 
            obj["exchange_logo"] = "https://static.rupeezy.in/stock_images/svgs/MCX.svg"
            break; 
        }
        switch (obj._source.sInstrumentName) {
            case 'EQUITIES':
              if (obj._source.nIsIndex === 1) {
                obj["type"] = "INDEX";
                obj["description"] = obj._source.sSymbol + " Index";
              } else {
                obj["type"] = obj._source.sSeries;
                obj["description"] = obj._source.sSecurityDesc;
              }
              obj["symbol"] = obj._source.sExchange + ":" + obj._source.sSymbol + "-" + obj._source.sSeries;
              obj["ticker"] = obj._source.sExchange + ":" + obj._source.sSymbol + "-" + obj._source.sSeries;
              obj["name"] = obj._source.sExchange + ":" + obj._source.sSymbol + "-" + obj._source.sSeries;
              break;
            case 'OPTSTK':
              obj["symbol"] = obj._source.sExchange + ":" + obj._source.sSecurityDesc;
              obj["ticker"] = obj._source.sExchange + ":" + obj._source.sSecurityDesc;
              obj["name"] = obj._source.sExchange + ":" + obj._source.sSecurityDesc;
              obj["type"] = "Options";
              obj["description"] = obj._source.sSymbol + " " + obj._source.nExpiryDate1.slice(-2) + " " + obj._source.nExpiryDate2 + " " + parseInt(obj._source.nStrikePrice) / obj._source.DecimalLocator + " " + obj._source.sOptionType;
              break;
            case 'FUTSTK':
            case 'FUTIDX':
              obj["symbol"] = obj._source.sExchange + ":" + obj._source.sSecurityDesc;
              obj["ticker"] = obj._source.sExchange + ":" + obj._source.sSecurityDesc;
              obj["name"] = obj._source.sExchange + ":" + obj._source.sSecurityDesc;
              obj["type"] = "Futures";
              obj["description"] = obj._source.sSymbol + " " + obj._source.nExpiryDate1.slice(-2) + " " + obj._source.nExpiryDate2;
              break;
            case 'OPTIDX':
              obj["symbol"] = obj._source.sExchange + ":" + obj._source.sSecurityDesc;
              obj["ticker"] = obj._source.sExchange + ":" + obj._source.sSecurityDesc;
              obj["name"] = obj._source.sExchange + ":" + obj._source.sSecurityDesc;
              obj["type"] = "Options";
              obj["description"] = obj._source.sSymbol + " " + obj._source.nExpiryDate1.slice(-2) + " " + obj._source.nExpiryDate2 + " " + parseInt(obj._source.nStrikePrice) / obj._source.DecimalLocator + " " + obj._source.sOptionType;
              break;
            case 'FUTCOM':
              var exp_date = new Date(obj._source.exp);
              let data = obj._source.sExchange + ":" + obj._source.sSymbol + obj._source.nExpiryDate1.toString().slice(-2) + exp_date.toLocaleString('default', { month: 'short', timeZone: 'UTC' }).toUpperCase().slice(0, 3) + "FUT";
              obj["symbol"] = data;
              obj["ticker"] = data;
              obj["name"] = data;
              obj["type"] = "Futures";
              obj["description"] = obj._source.sSymbol + " " + obj._source.nExpiryDate1 + " FUT";
              break;
            case 'OPTFUT':
              let data2 = obj._source.sExchange + ":" + obj._source.sSymbol + obj._source.nExpiryDate1.slice(-2) + obj._source.nExpiryDate1.slice(2, 5) + (parseInt(obj._source.nStrikePrice) / 100).toString() + obj._source.sOptionType;
              obj["symbol"] = data2;
              obj["ticker"] = data2;
              obj["name"] = data2;
              obj["type"] = "Options";
              obj["description"] = obj._source.sSymbol + " " + obj._source.nExpiryDate1 + " " + (parseInt(obj._source.nStrikePrice) / 100).toString() + " " + obj._source.sOptionType;
              break;
            case 'FUTCUR':
              obj["symbol"] = "NSE" + ":" + obj._source.sSecurityDesc;
              obj["ticker"] = "NSE" + ":" + obj._source.sSecurityDesc;
              obj["name"] = "NSE" + ":" + obj._source.sSecurityDesc;
              obj["type"] = "Futures";
              obj["description"] = obj._source.sSymbol + " " + obj._source.nExpiryDate1 + " FUT";
              obj["exchange"] = "NSE";
              break;
            case 'OPTCUR':
              obj["symbol"] = "NSE" + ":" + obj._source.sSecurityDesc;
              obj["ticker"] = "NSE" + ":" + obj._source.sSecurityDesc;
              obj["name"] = "NSE" + ":" + obj._source.sSecurityDesc;
              obj["type"] = "Options";
              obj["description"] = obj._source.sSymbol + " " + obj._source.nExpiryDate1 + " " + (parseFloat(obj._source.nStrikePrice) / obj._source.DecimalLocator).toString() + " " + obj._source.sOptionType;
              obj["exchange"] = "NSE";
              break;
            default:
              obj["type"] = "NO IDEA";
              obj["description"] = obj._source.sSecurityDesc;
              obj["type"] = obj._source.sInstrumentName;
          }
        
        // if( obj._source.sInstrumentName == 'EQUITIES'){
            
        //     if(obj._source.nIsIndex == 1){
        //         obj["type"]="INDEX"
        //         obj["description"]=obj._source.sSymbol+" Index"
        //         obj["symbol"] = obj._source.sExchange+ ":"+obj._source.sSymbol+"-EQ"
        //         obj["ticker"] = obj._source.sExchange+ ":"+obj._source.sSymbol+"-EQ"
        //         obj["name"] = obj._source.sExchange+ ":"+obj._source.sSymbol+"-EQ"
        //     }else{
        //         obj["type"]=obj._source.sSeries
        //         obj["description"]=obj._source.sSecurityDesc
        //         obj["symbol"] = obj._source.sExchange+ ":"+obj._source.sSymbol+"-"+obj._source.sSeries
        //         obj["ticker"] = obj._source.sExchange+ ":"+obj._source.sSymbol+"-"+obj._source.sSeries
        //         obj["name"] = obj._source.sExchange+ ":"+obj._source.sSymbol+"-"+obj._source.sSeries
        //     }
           
        // }else if ( obj._source.sInstrumentName == 'OPTSTK'){
        //     obj["symbol"] = obj._source.sExchange+ ":"+obj._source.sSecurityDesc
        //     obj["ticker"]  = obj._source.sExchange+ ":"+obj._source.sSecurityDesc
        //     obj["name"]  = obj._source.sExchange+ ":"+obj._source.sSecurityDesc
        //     obj["type"]="Options"
        //     obj["description"]= obj._source.sSymbol + " " +  obj._source.nExpiryDate1.slice(-2)+" "+ obj._source.nExpiryDate2 + " "+parseInt(obj._source.nStrikePrice)/obj._source.DecimalLocator+ " "+obj._source.sOptionType
        // }
        // else if(obj._source.sInstrumentName == 'FUTSTK'){
        //     obj["symbol"] = obj._source.sExchange+ ":"+obj._source.sSecurityDesc
        //     obj["ticker"]  = obj._source.sExchange+ ":"+obj._source.sSecurityDesc
        //     obj["name"]  = obj._source.sExchange+ ":"+obj._source.sSecurityDesc
        //     obj["type"]="Futures"
        //     obj["description"]= obj._source.sSymbol + " " +  obj._source.nExpiryDate1.slice(-2)+" "+ obj._source.nExpiryDate2
        // }else if(obj._source.sInstrumentName == 'FUTIDX'){
        //     obj["symbol"] = obj._source.sExchange+ ":"+obj._source.sSecurityDesc
        //     obj["ticker"]  = obj._source.sExchange+ ":"+obj._source.sSecurityDesc
        //     obj["name"]  = obj._source.sExchange+ ":"+obj._source.sSecurityDesc
        //     obj["type"]="Futures"
        //     obj["description"]= obj._source.sSymbol + " " +  obj._source.nExpiryDate1.slice(-2)+" "+ obj._source.nExpiryDate2
        // }
        // else if (obj._source.sInstrumentName == 'OPTIDX'){
        //     obj["symbol"] = obj._source.sExchange+ ":"+obj._source.sSecurityDesc
        //     obj["ticker"]  = obj._source.sExchange+ ":"+obj._source.sSecurityDesc
        //     obj["name"]  = obj._source.sExchange+ ":"+obj._source.sSecurityDesc
        //     obj["type"]="Options"
        //     obj["description"]= obj._source.sSymbol + " " +  obj._source.nExpiryDate1.slice(-2)+" "+ obj._source.nExpiryDate2 + " "+parseInt(obj._source.nStrikePrice)/obj._source.DecimalLocator+ " "+obj._source.sOptionType
        // }else if (obj._source.sInstrumentName == "FUTCOM"){
        //     var exp_date =  new Date(obj._source.exp)
        //     let aa= obj._source.sExchange+ ":"+ obj._source.sSymbol+obj._source.nExpiryDate1.toString().slice(-2)+exp_date.toLocaleString('default',{month: 'short', timeZone: 'UTC'}).toUpperCase().slice(0,3)+"FUT"
        //     obj["symbol"] = aa
        //     obj["ticker"] = aa
        //     obj["name"] = aa
        //     obj["type"]="Futures"
        //     obj["description"] = obj._source.sSymbol + " "+ obj._source.nExpiryDate1 + " FUT"
        // }
        // else if (obj._source.sInstrumentName =='OPTFUT'){
        //     let aa = obj._source.sExchange+ ":"+ obj._source.sSymbol+obj._source.nExpiryDate1.slice(-2)+obj._source.nExpiryDate1.slice(2,5) + (parseInt(obj._source.nStrikePrice)/100).toString()+obj._source.sOptionType
        //     obj["symbol"] = aa
        //     obj["ticker"] = aa
        //     obj["name"] = aa
        //     obj["type"]="Options"
        //     obj["description"] = obj._source.sSymbol + " "+ obj._source.nExpiryDate1 + " "+(parseInt(obj._source.nStrikePrice)/100).toString() + " "+obj._source.sOptionType
        // }
        // else if(obj._source.sInstrumentName == "FUTCUR"){
        //     obj["symbol"] = "NSE"+ ":"+obj._source.sSecurityDesc
        //     obj["ticker"]  = "NSE"+ ":"+obj._source.sSecurityDesc
        //     obj["name"]  = "NSE"+ ":"+obj._source.sSecurityDesc
        //     obj["type"]="Futures"
        //     obj["description"]= obj._source.sSymbol + " " +  obj._source.nExpiryDate1 + " FUT"
        //     obj["exchange"] = "NSE"
        // }
        // else if(obj._source.sInstrumentName == "OPTCUR"){
        //     obj["symbol"] = "NSE"+ ":"+obj._source.sSecurityDesc
        //     obj["ticker"]  = "NSE"+ ":"+obj._source.sSecurityDesc
        //     obj["name"]  = "NSE"+ ":"+obj._source.sSecurityDesc
        //     obj["type"]="Options"
        //     obj["description"]= obj._source.sSymbol + " " +  obj._source.nExpiryDate1 + " " + (parseFloat(obj._source.nStrikePrice)/obj._source.DecimalLocator).toString()+" " +obj._source.sOptionType
        //     obj["exchange"] = "NSE"
        // }
        // else{
        //     obj["type"]="NO IDEA"
        //     obj["description"]= obj._source.sSecurityDesc
        //     obj["type"] = obj._source.sInstrumentName
        // }
    }

    //Get the token from scrip symbol
    async getTokensFromSymbol(symbols){
        if(symbols.length == 1 ){
            var cached = this._symbolToTokenMap[symbols[0]]
            if(cached){
                var response = [{
                    symbol: symbols[0],
                    token: cached.token, 
                    market_segment_id: cached.market_segment_id
                }]
                return Promise.resolve(response)
            }
        }
        return this._inHouseRequester.sendRequest(this._inHouseUrl,"symbolsToTokens",{symbols: symbols},undefined,"POST").then((response)=>{
            response.forEach(element => {
                if (element.market_segment_id == 8 ||element.market_segment_id == "8" ){
                    element.market_segment_id = 3 
                }
                this._symbolToTokenMap[element.symbol] = {
                    token: element.token, 
                    market_segment_id: element.market_segment_id
                }
            });
            return Promise.resolve(response)
        })
    }

    async _getSymbolFromTokens(token){
        if (this._tokenToSymbolMap[parseInt(token.market_segment_id)] && this._tokenToSymbolMap[parseInt(token.market_segment_id)][parseInt(token.token)]){
            return Promise.resolve({
                token: token.token, 
                market_segment_id: token.market_segment_id, 
                symbol: this._tokenToSymbolMap[parseInt(token.market_segment_id)][parseInt(token.token)]
            })
        }else{
            return this._inHouseRequester.sendRequest(this._inHouseUrl, 'tokenToSymbol/'+token.market_segment_id+"/"+token.token, undefined,undefined,"GET").then((response)=>{
                if (this._tokenToSymbolMap[parseInt(token.market_segment_id)] == undefined){
                    this._tokenToSymbolMap[parseInt(token.market_segment_id)] = {}
                    this._tokenToSymbolMap[parseInt(token.market_segment_id)][parseInt(token.token)] = response.symbol
                }else{
                    this._tokenToSymbolMap[parseInt(token.market_segment_id)][parseInt(token.token)] = response.symbol
                }
                return Promise.resolve(response)
            })
        }
    }


}
function defaultConfiguration() {
    return {
        supports_search: true,
        supports_group_request: true,
        has_seconds: true,
        has_ticks: true,
        supported_resolutions: [
            '5S',
            '15S',
            '30S',
            '1',
            '2',
            '3',
            '4',
            '5',
            '10',
            '15',
            '30',
            '45',
            '60',
            '120',
            '240',
            '1D',
            '1W',
            '1M',
        ],
        supports_marks: false,
        supports_timescale_marks: true,
        "exchanges": [
            {
                "value": "All Exchanges",
                "name": "All Exchanges",
                "desc": ""
            },
            {
                "value": "NSE",
                "name": "NSE",
                "desc": "National Stock Exchange "
            },
            {
                "value": "BSE",
                "name": "BSE",
                "desc": "Bombay Stock Exchange "
            },
            {
                "value": "MCX",
                "name": "MCX",
                "desc": "Multi Commodities Exchange"
            }
        ],
        // "exchanges": ["NSE","MCX"],
        "symbols_types": [{"name":"All", "value": 5},{"name":"Stocks", "value": 1},{"name":"Options", "value": 2},{"name":"Futures", "value": 3},{"name":"Indices", "value": 4}],
    };
}
