import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import BasketketHeader from "../Header/bacsketHeader";
import {ExecutePublicBasket,setPublicBasketMargin,basketOrderAnalyse,sharedBasketExecute,setBasketDetails, setCurrentBasketState, setOrderListenerData} from "../../../slices/basketOrderSlice";
import {ReactComponent as SearchIcon} from "../../../assets/icons/search-normal.svg";
import './basketDetails.css'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import Form from "react-bootstrap/Form";
import {DataFeedInstance} from "../../../chart-datafeed/udf-compatible-datafeed";
import SearchData from "./SearchData";
import EachStock, {OrderTypeModel, productTypeModel, ValidtyTypeModel} from "../eachStock/eachStock";
import AddToBasket from "../AddToBasket/addToBasket";
import {OrderOMSFeedSub} from "../../../sub";
import flowBackEndService from "../../../services/flowBackendApi";
import {toast} from "react-toastify";
import {basketSubServices} from "../basketSub";
import {BrokerApiInstance} from "../../../chart-brokerapi/broker";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import AllBasketOrder from "./AllBasketOrder";
import {logMessage} from "../../../utils/helpers";
import flowBackEndServices2 from "../../../services/flowBackendServices";
import {SegmentExchangeMap} from "../../common/number_color"

export const collectionStatus = {
    collectionAllow: false
}

export const collectionArray = {
    OrderCollection: [],
    IsExcecuted: false,
    basketWhenExce: []
}

// setting property
Object.defineProperty(collectionStatus, "setCollectionStatus", {
    set : function (value) {
        this.collectionAllow = value;
    }
});

Object.defineProperty(collectionArray, "setCollectionArray", {
    set : function (value) {
        this.OrderCollection = value;
    }
});

Object.defineProperty(collectionArray, "setCollectionIsExcecuted", {
    set : function (value) {
        this.IsExcecuted = value;
    }
});

Object.defineProperty(collectionArray, "setBasketWhenExce", {
    set : function (value) {
        this.basketWhenExce = value;
    }
});


const BasketDetails = ({data}) => {
    const dispatch = useDispatch();
    const childRef = useRef();

    const [ sData, setSData ] = useState([])
    const [ basketOrders, setbasketOrders ] = useState([])
    const [ masterOrder, setMasterOrder ] = useState([])
    const [ isExecuted, setIsExecuted ] = useState(data.isExecuted)
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);
    const [formState, setFormState] = useState({userInput: '', exchange: 'All Exchanges', symbolType: '5'});

    const  listDataMain = useSelector(((state) => state.basketOrder.listDataMain),(left,right)=>{
       return JSON.stringify(left) == JSON.stringify(right)
    });
    const currentModification  = useSelector(((state) => state.basketOrder.currentModification),shallowEqual);
    const execute_public_basket  = useSelector(((state) => state.basketOrder.execute_public_basket),shallowEqual);
    const user = useSelector((state => state.authUser.user),shallowEqual);

    // const [ ordersNow, setOrdersNow ] = useState(null)
    const [typingTimer, setTypingTimer] = useState(null)

    const memData = useMemo(() => data, [data]);
    const memBasketOrders = useMemo(() => basketOrders, [basketOrders]);

    const memMasterOrder = useMemo(() => masterOrder, [masterOrder]);
    const memisExecuted = useMemo(() => isExecuted, [isExecuted]);
    const [prevOrders,setPrevOrders]=useState(null)

    const [ totalMargin, setTotalMargin ] = useState(null)

    async function executeFullOrder() {
        setIsLoading(true)
        collectionStatus.setCollectionStatus = true
        collectionArray.setCollectionArray = []
        collectionArray.setCollectionIsExcecuted = false;
       let orders =  listDataMain.find(d => d.id === data.id)
       collectionArray.setBasketWhenExce = orders.basketOrders
        await settingListnerDataMaster()
        basketOrders.map(order => executeOrderSingle(order));
        sessionStorage.removeItem("public_basket_id")
        dispatch(ExecutePublicBasket(false))
    }

    async function executePublicFullOrder(orders) {
        setIsLoading(true)
        dispatch(sharedBasketExecute())
        collectionStatus.setCollectionStatus = true
        collectionArray.setCollectionArray = []
        collectionArray.setCollectionIsExcecuted = false;
       collectionArray.setBasketWhenExce = orders
        await settingListnerDataMaster()
        orders.map(order => executeOrderSingle(order));
        sessionStorage.removeItem("public_basket_id")
        dispatch(ExecutePublicBasket(false))
    }

    useEffect(()=>{
        if(execute_public_basket){
            let orders =  listDataMain.find(d => d.id === data.id)
            if(orders){
                executePublicFullOrder(orders.basketOrders)
            }
        }
    },[listDataMain])

    useEffect(()=>{
        let id = sessionStorage.getItem("public_basket_id")
        if(id && id!=""){
            let orders =  listDataMain.find(d => d.id === data.id)
            if(orders) callTotalMarginApi(orders.basketOrders)
        }
    },[listDataMain])

   async function settingListnerDataMaster(){
        let val = { ...data,basketOrders: basketOrders}
        await dispatch(setOrderListenerData(val))
    }

    function fetchAllOrder() {
        BrokerApiInstance.orders().then(d => setMasterOrder(d))
    }

    function setSingleExcData() {
        let orders =  listDataMain.find(d => d.id === data.id)
        collectionArray.setBasketWhenExce = orders.basketOrders
    }

    function executeOrderSingle(order) {
        DataFeedInstance.tokenInfo({market_segment_id: order.marketSegmentId , token: order.token }).then(values => {
            let listenerId =  order.id;
            BrokerApiInstance.placeOrder({
                symbol: values.symbol,
                side: order.buySell,
                margin: order.productType,
                qty: order.quantity,
                limitPrice: order.price,
                triggerPrice: order.triggerPrice,
                order_type: order.orderType,
                validity: order.validity,
                is_amo: order.isAmo,
                origin:'complete_order_basket'
            },listenerId).then(()=>{
                // logMessage(`ORDDDDDD ${listenerId}`);
                window.GtmPusher({
                    event: 'tv-order-placed-basketMulti'
                })
            })
        })
    }
    useEffect(()=>{
        let orders =  listDataMain.find(d => d.id === data.id)
        setPrevOrders(orders?.basketOrders.length)
    },[])

    useEffect(()=>{
        let orders =  listDataMain.find(d => d.id === data.id)
        if(orders) setbasketOrders(orders?.basketOrders)
        if(orders) setIsExecuted(orders.isExecuted)
        if(orders?.basketOrders.length > 0 && orders?.basketOrders.length==prevOrders){
                callTotalMarginApi(orders.basketOrders)
        }
    },[prevOrders])

    function updateDataNewAdd() {
        let orders =  listDataMain.find(d => d.id === data.id)
        logMessage("basketDetails.js: UpdateDataNewAdd called")
        if(orders) setbasketOrders(orders?.basketOrders)
        if(orders) setIsExecuted(orders.isExecuted)
        if(orders?.basketOrders.length > 0){
            if(orders?.basketOrders.length!=prevOrders && prevOrders!=null){
                callTotalMarginApi(orders.basketOrders)
            }
        }
    }



    let subscription = undefined

    function checkValidOrderAndUpdate(values, idNum) {
        let ordersMain =  collectionArray.basketWhenExce
        let currData = ordersMain.find(d => d.id === idNum)
        if(currData === undefined){
            BrokerApiInstance._host.showNotification("Order Not found","" ,0)
            // childRefEachStock.current.closeLodding();
            callCloseLoddingChild()
        }
        let newData = { ...currData,orderId: values.order.order_id}
        let orderlist = [...ordersMain];
        orderlist = orderlist.map(u => u.id !== idNum ? u : newData);
        let orders =  listDataMain.find(d => d.id === data.id)
        const dataMY = {basketName: orders.basketName,orders:orderlist}
        setIsLoading(true)
        flowBackEndService.UpdateBasket(dataMY,user.access_token,orders.id)
            .then(value => {
                if(value.data.status ===  'success'){
                      // childRefEachStock.current.closeLodding();
                      callCloseLoddingChild()
                     // collectionArray.setBasketWhenExce = []
                       fetchAllOrder();
                      setbasketOrders(orderlist)
                      basketSubServices.setNewBasket(currData)
                }else{
                    BrokerApiInstance._host.showNotification('Error update',"Order Not Updated" ,0)
                    toast.error(`Error update ${value?.data?.message}`);
                }
            })
            .catch(error => {
                toast.error(`${error.response.data.message}`)
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            })

    }


    function triggerOrderExecutionApi(OrderList,curOrder) {
        // console.log("BATCH ORDER",OrderList,curOrder)
        let newListOrderIDs = []
        curOrder.map((value, index) => {
            const ifPresent = OrderList.find(v => v.basketId === value.id);
            if(ifPresent){
                newListOrderIDs = [...newListOrderIDs,{...value,orderId: ifPresent.orderId}]
            }
        })

        // console.log("BBT",newListOrderIDs)
        if(curOrder.length !== newListOrderIDs.length){
            logMessage(`BasketDetals : order mismatch`)
            setIsLoading(false)
            return
        }

        collectionArray.setCollectionIsExcecuted = true

        setIsLoading(true)
        const datas = {basketName: data.basketName,isExecuted: true,orders:newListOrderIDs}
        flowBackEndService.UpdateBasket(datas,user.access_token,data.id)
            .then(value => {
                if(value.data.status ===  'success'){
                    fetchAllOrder();
                    collectionStatus.setCollectionStatus = false
                    collectionArray.setCollectionIsExcecuted = true
                    // collectionArray.setBasketWhenExce = []
                    setIsLoading(false)
                     setIsExecuted(true)
                     setbasketOrders(newListOrderIDs)
                     basketSubServices.setNewBasket(newListOrderIDs)
                }else{
                    toast.error(`Error update ${value?.data?.message}`);
                }
            })
            .catch(error => {
                toast.error(`${error.response.data.message}`)
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            })

    }

    function getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }

    function setMultiOrderValue(values, idNum) {
        collectionArray.setCollectionArray = [...collectionArray.OrderCollection,{orderId: values.order.order_id, basketId: idNum}];
        let uniqList = getUniqueListBy(collectionArray.OrderCollection,'basketId')
        let orders =  collectionArray.basketWhenExce
        if(uniqList.length === orders.length){
            if(collectionArray.IsExcecuted === false) triggerOrderExecutionApi(uniqList,orders)
        }
    }



    function onAnotherStatusUpdateStatusOfOrder(values) {
        if(values.order?.listenerId){
            let idNum = +(values.order.listenerId)
            if(collectionStatus.collectionAllow) {
                if(collectionArray.basketWhenExce.find(d => d.id === idNum)) fetchAllOrder()
            }else {
                if(collectionArray.basketWhenExce.find(d => d.id === idNum)) fetchAllOrder()
            }
        }
    }

    function processOrderNow(values) {
        if(values.order?.listenerId){
            let idNum = +(values.order.listenerId)
            if(collectionStatus.collectionAllow) {
                if(values.order.status === 6) setMultiOrderValue(values,idNum)
            }else {
                if(values.order.status === 6) checkValidOrderAndUpdate(values,idNum)
            }
        }
    }

    // useEffect(() => {
    //     if(ordersNow) processOrderNow()
    // }, [ordersNow]);

   /* useEffect(() => {
        collectionArray.setCollectionArray = []
    }, [orderListenerData]);

    useEffect(() => {
        console.log("ORDEER",orderListenerData?.basketOrders)
    }, [orderListenerData?.basketOrders]);*/

    function listenOrdereExcecution(){
        subscription = OrderOMSFeedSub.OrderListener().subscribe(values => {
            // console.log("ORDER SS",values)
            logMessage(`ORDER SS ${JSON.stringify(values)}`);
            // if(values?.order && values.order.status === 6) setOrdersNow(values)
            if(values?.order && values.order.status === 6)  processOrderNow(values)
            if(values?.order && values.order.status === 5)  hardcheckWhenReject(values)
            if (values?.order && values.order.status !== 6) onAnotherStatusUpdateStatusOfOrder(values)
            if(values.response && values.response.error) setIsLoading(false)
        })
    }

    function hardcheckWhenReject(values){
        if(values.order?.listenerId){
            let idNum = +(values.order.listenerId)
           let val =  collectionArray.OrderCollection.find(d => d.basketId === idNum);
            if(val){
                return
            }
            if(collectionStatus.collectionAllow) {
                 setMultiOrderValue(values,idNum)
            }else {
                 checkValidOrderAndUpdate(values,idNum)
            }
        }
    }


    useEffect(() => {
        listenOrdereExcecution()
        fetchAllOrder()
        return () => {
            subscription?.unsubscribe();
        };
    }, []);

    useEffect(() => {
      if(listDataMain) updateDataNewAdd()
    }, [listDataMain])


    useEffect(() => {
       dispatch(setBasketDetails(data))
    }, [data])

    useEffect(() => {
        // console.log("isExecuted",isExecuted,data.isExecuted)
    }, [isExecuted])


    useEffect(() => {
           // console.log("basketOrders",basketOrders);
      // if(basketOrders.length > 0) setbasketSize(basketOrders.length)
    }, [basketOrders])



    useEffect(() => {
       if(currentModification) openModel()
    }, [currentModification])


    useEffect(() => {
        searchDataFunc(formState.userInput)
    }, [formState.symbolType,formState.exchange])

    useEffect(() => {
        // searchAfterSomeTime()
    }, [formState.userInput])



    const onBackClick = () => {
        if(isLoading) return null;
        let state =  {id: 1,param: null,url:'BucketList/EmptyState'};
       sessionStorage.removeItem("public_basket_id")
        dispatch(setCurrentBasketState(state))
    }

    const openModel = () => {
        childRef.current.getAlert();
    }

    const searchDataFunc = (value) => {
        if(value.length === 0){
            return
        }
        let symbolType = Number(formState.symbolType)
        setIsLoadingSearch(true)
        DataFeedInstance.searchSymbols(formState.userInput,formState.exchange,symbolType,(res) => {
            // console.log("RES",res);
            setSData(res)
            setIsLoadingSearch(false)
        })
    }

    function handleFormStateChange(event)  {
        const { name, value } = event.target;
        setFormState({ ...formState, [name]: value });

        clearTimeout(typingTimer);
        if (value.length > 0) {
            const timerOut = setTimeout(() =>{
                searchDataFunc(value)
            }, 500);
            setTypingTimer(timerOut)
        }
    }

    const formStateChange = (key,value) => {
        setFormState({ ...formState, [key]: value });
    }

    const clearInput = () => {
        setFormState({ ...formState, userInput: '' });
    }

    function updateBasketArrangeOrder(dataOrderd,isExecuted_my= null,callback) {
        let datas = {};
        if(isExecuted_my === null){
            datas =  {basketName: data.basketName,orders:dataOrderd}
        }else {
            datas =  {basketName: data.basketName,isExecuted: isExecuted_my,orders:dataOrderd}
        }
        flowBackEndService.UpdateBasket(datas,user.access_token,data.id)
            .then(value => {
                if(value.data.status ===  'success'){
                    // toast.success(`Basket order Updated ${value.data.message}`);
                     basketSubServices.setNewBasket(dataOrderd)
                     // if(typeof callback === 'function') callback()
                }else{
                    // toast.error(`Error update ${value?.data?.message}`);
                }
            });
    }

    function reverseOrder(){
        let reverseData = [];
        basketOrders.map(value => {
            reverseData = [...reverseData,{...value,buySell: value.buySell === 1 ? -1: 1,orderId: ""}]
        })
        let reverseDataNew =  reverseData.reverse();
        setbasketOrders(reverseDataNew)
        setIsExecuted(false)
        // updateBasketArrangeOrder(reverseDataNew, false)

    }

    // const reverseOrderCallBack = useCallback(() => {
    //     reverseOrder()
    // }, []);

    const updateBasketOrderCallBack = useCallback((data) => {
        setbasketOrders(data)
        updateBasketArrangeOrder(data)
    }, []);




   async function resetOrders(){
        let reverseData = [];
        basketOrders.map(value => {
            reverseData = [...reverseData,{...value,orderId: ""}]
        })
        setbasketOrders(reverseData)
        setIsExecuted(false)
        // await dispatch(setOrderListenerData(null))
        BrokerApiInstance.removeOrderLids(reverseData.map((value)=>value.id))
        updateBasketArrangeOrder(reverseData, false)
    }

    let dragProps = {
        onDragEnd(fromIndex, toIndex) {
            const data = [...basketOrders];
            const item = data.splice(fromIndex, 1)[0];
            data.splice(toIndex, 0, item);
            setbasketOrders(data)
            updateBasketArrangeOrder(data)
        },
        handleSelector: ".grab",
        nodeSelector:'.each_box',
        enableScroll:true,
    };

   /* function getBasketOrders() {

        if(basketOrders.length === 0){
            return (<div className="d-flex flex-column justify-content-start align-items-center">
                <div className=""><img src={frameIcon} alt={''}/></div>
                <div className="heading2 mt-5">No Stocks Found</div>
                <div className="text1 colorGrey currentColor">Search and Add upto 10 stocks in this basket</div>
            </div>);
        }

        return (

            <div className="d-flex flex-column justify-content-start align-items-start m-4">
                <div className="d-flex scroll_box2 flex-column align-items-stretch w-100 justify-content-between">
                    <ReactDragListView   {...dragProps}>
                     <table className="baskTable">
                         <thead className="card_bg">
                         <tr className="currentText">
                             <th>&nbsp;</th>
                             <th onClick={reverseOrder}>Name</th>
                             <th>LTP</th>
                             <th>Price</th>
                             <th>Qty.</th>
                             <th>Status</th>
                         </tr>
                         </thead>

                       <tbody>
                                {
                                    basketOrders.map((item,index) => {
                                        const id1 = nextId("BE-");
                                        return(
                                            <EachStock ref={childRefEachStock} key={id1} data={item} index={index} masterOrder={masterOrder} isExecuted={isExecuted} setSingleExcData={setSingleExcData}/>
                                        )
                                    })
                                }
                       </tbody>

                     </table>
                    </ReactDragListView>
                </div>
            </div>
        )

    }*/

    function callCloseLoddingChild() {
        childRef.current.callChildMethod();
    }

    const productMap = {
        "1": "INTRADAY",
        "2": "MTF",
        "3": "DELIVERY",
    }
    const VarietyMap = {
        "1": "RL",
        "2": "RL-MKT",
        "3": "SL",
        "4": "SL-MKT",
    }
    function formateMarginData(data) {
       return  {
            "token": data.token,
            "exchange": SegmentExchangeMap[data.marketSegmentId],
            "transaction_type": (data.buySell == 1 ? "BUY" : "SELL"),
            "product": productMap[data.productType],
            "variety": VarietyMap[data?.orderType],
            "quantity": data.quantity * data.stockData.lotSize,
            "price": data.price
        }
    }

    function callTotalMarginApi(orders) {
        let data = orders.map(d => formateMarginData(d))
        flowBackEndServices2.getTotalMargin({orders: data},user.access_token).then(value => {
            if(value.status === 200 && value.data.status === 'success'){
                dispatch(setPublicBasketMargin(value.data))
               setTotalMargin(value.data)
            }
        })
    }

    const analyseGraph = () =>{
        let optionAvailable = false
        let orders =  listDataMain.find(d => d.id === data.id)
        orders.basketOrders.map((d)=>{
            if(d.stockData.marketSegmentId==2){
                optionAvailable = true
            }
        })
        if(optionAvailable){
            let state =  {id: 5,param: orders,url:'basketAnalyse'};
            dispatch(setCurrentBasketState(state))
            dispatch(basketOrderAnalyse())
        }else{
            toast.error("At least one order of any option should be in the basket")
        }
    }

    return (
        <React.Fragment>
            <BasketketHeader>
                <span className="heading2">  <i onClick={onBackClick} className="fa fa-arrow-left c-pointer" aria-hidden="true"/>&nbsp; {data.basketName} ({basketOrders.length}/10)</span>
            </BasketketHeader>

            { currentModification && (
                <AddToBasket ref={childRef} stockData={currentModification} modification={'M'}/>
            )}

            {
                basketOrders.length > 0 && (
                    <div className="bottom_execute_bar currentBg2">
                        {isExecuted ? null:
                            <React.Fragment>
                                <Button disabled={isLoading} className="blue_btn my-3 w-25 mr-5" onClick={executeFullOrder} variant="primary" type="submit">
                            {isLoading && (
                                <Spinner className="mr-2" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                            )}
                            Execute
                        </Button>
                                <Button style={{"marginLeft":"19%"}} className="analyse_btn my-3 w-25" onClick={analyseGraph} variant="primary" type="submit">
                            Analyse
                        </Button>
                            </React.Fragment>
                        }

                        { isExecuted && (
                            <React.Fragment>
                                <div onClick={resetOrders} className="btn blue_btn w-25 c-pointer mr-5">Reset</div>
                                {/*<div onClick={reverseOrder} className="btn blue_btn_outline text-nowrap w-25 c-pointer mr-5">Reverse Order</div>*/}
                            </React.Fragment>
                        )}

                        {(!isExecuted) && (
                            <div className='d-flex flex-row'>
                                    <div className="d-flex ml-4 flex-column justify-content-evenly align-items-left">
                                        <div className="text_grey currentText">Initial Margin</div>
                                        <div className="heading2">{totalMargin?totalMargin.initial_margin:0}</div>
                                    </div>
                                    <div className="d-flex ml-4 flex-column justify-content-evenly align-items-left">
                                    <div className="text_grey currentText">Final Margin</div>
                                    <div className="heading2">{totalMargin?totalMargin.required_margin:0}</div>
                                </div>
                            </div>
                        )}

                    </div>
                )
            }



            <div className="d-flex  w-100">

                <Form  className="w-100 pt-3 px-5 ">

                    <Form.Group className="position-relative" controlId="formBasicEmail">

                        {formState.userInput.length === 0 && (
                            <div className="mysearch c-pointer"><SearchIcon/></div>
                        )}
                        {formState.userInput.length > 0 && (
                            <React.Fragment>
                                <div className="myclear"> <button onClick={clearInput} className="btn clearbtn">Clear</button> </div>
                                <SearchData data={sData} isLoading={isLoadingSearch} formState={formState} formStateChange={formStateChange} />
                            </React.Fragment>
                            )}
                        <Form.Control type="text"
                                      name="userInput"
                                      required
                                      autoComplete="off"
                                      value={formState.userInput}
                                      onChange={handleFormStateChange}
                                      placeholder="Search & Add Stock" />
                        <Form.Control.Feedback type='invalid' className="d-flex flex-row">

                        </Form.Control.Feedback>
                    </Form.Group>

                </Form>


            </div>

            {/*{getBasketOrders()}*/}

            <AllBasketOrder basketOrders={memBasketOrders}
                            masterOrder={memMasterOrder}
                            isExecuted={memisExecuted}
                            updateBasketOrderCallBack={updateBasketOrderCallBack}
                            ref={childRef}
                            data={memData}/>



        </React.Fragment>
    );
};

export default BasketDetails;








