import React, { useEffect, useState } from 'react';
import Background from "../BackGround/background";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import OtpInput from "react-otp-input";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import flowBackEndServices2 from "../../services/flowBackendServices";
import { loginEvent, setEmailAndMobile,setOtpData } from "../../slices/authSlice";
import { toast } from "react-toastify";
import refresh from "../../assets/images/refresh-circle.svg";
import Timer from "../common/timer";
import { getBrowserDetails } from "../../comman/status";
import { QUERY_PARAM } from "../../utils/config";
import authService from "../../services/authService";
import RiskDisclosure from './RiskDisclosure';
import KraStatus from "./kraStatus"


function loginOTPVerify(props) {

    const { state } = useLocation();
    const [kra,setKra]=useState(0)
    const navigate = useNavigate();
    const { data, user, themeData } = useSelector((state) => state.authUser);
    const [aeroUrl,setAeroUrl]=useState(null)

    const [Otp, setOtp] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingResed, setIsLoadingResed] = useState(false);
    const dispatch = useDispatch();
    const [showDisclosure,setShowDisclosure] = useState(false)
    const [showNext,setShowNext] = useState(false)


    const [show, setShow] = useState(false);
    const [showResetBtn, setShowResetBtn] = useState(false);
    const [otpType, setOtpType] = useState('otp');
    const [isPermissionGranted,changePermissionGrant] = useState(false)
    // var isPermissionGranted = false; 

    // function changePermissionGrant(val) {
    //     console.log(val)
    //     isPermissionGranted = val;
    // }

    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
    
    const handleResize = () => {
        setIsDesktop(window.innerWidth > 768);
      };
    
      useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    useEffect(() => {
        console.log("setOtpType data",data)
        if (data){
            if (data.data){
                console.log("setOtpType data.data",data.data.mfa_type)
                setOtpType(data.data.mfa_type)
            }else{
                console.log("setOtpType data",data.mfaType)
                setOtpType(data.mfaType)
            }
        }
        
    }, [data])

    useEffect(() => {
        if (data === null) {
            navigate("/")
        }
    }, [])

    useEffect(()=>{
        let ackDisc = localStorage.getItem("acknowledge_disclosure")
        if(ackDisc==null || ackDisc=="false"){
            setShowDisclosure(false)
        }else{
            setShowDisclosure(true)
        }

        return ()=>{
            localStorage.setItem("acknowledge_disclosure","false")
        }
    },[])


    const showResend = () => {
        setShowResetBtn(!showResetBtn)
    }

    function resendPassword() {
        sendToptSMS()
    }

    function setPermission(){
        changePermissionGrant(!isPermissionGranted)
    }

    function handleOnClick(event) {

        themeData.verifySSO ? verifyMfaSSO() : verifyMFA()

    }

    function verifyMFA() {
        const { deviceId, tempToken } = data;
        let mfaType = otpType;

        let browswe = getBrowserDetails()
        let deviceInfo = `${browswe?.browserName}`;
        let appVersion = `${browswe?.fullVersion}`;
        let os = `${browswe?.OSName}`;


        let dataSend = { otp: `${Otp}`, deviceId: deviceId, mfaType, deviceInfo, appVersion, os }
        setIsLoading(true)
        flowBackEndServices2.verifyMfa(dataSend, tempToken).then(value => {
            if (value?.status === 200) {
                if (value.data.status === 'success') {
                    setUpSessionData(value, dispatch, data)
                } else {
                    toast.error(`${value.data.message}`)
                }
            }
        }).catch(reason => {
            console.log(reason)
            toast.error(`invalid OTP`)
            setIsLoading(false)
        }).finally(() => setIsLoading(false))
    }


    function verifyMfaSSO() {
        const { deviceId, tempToken } = data;
        let mfaType = otpType;

        let browswe = getBrowserDetails()
        let deviceInfo = `${browswe?.browserName}`;
        let appVersion = `${browswe?.fullVersion}`;
        let os = `${browswe?.OSName}`;


        let dataSend = { otp: `${Otp}`, deviceId: deviceId, mfaType, deviceInfo, appVersion, os }
        setIsLoading(true)
        flowBackEndServices2.verifyMfaSSO(dataSend, tempToken).then(value => {
            if (value?.status === 200) {
                if (value.data.status === 'success') {
                    // login event
                    dispatch(setEmailAndMobile({ mobile: value.data.mobile, email: value.data.email }))
                    dispatch(loginEvent())
                    const params = localStorage.getItem('Q_PARAM_ALL')
                    const applicationId=params.split("=")
                    if(applicationId[1]=='at_g4FXzp00jB"'){
                        if(value.data.kra_status=="HOLD"){
                            setShowNext(true)
                            setKra(1)
            
                        }else if(value.data.kra_status=="REJECTED"){
                            setShowNext(true)
                            setKra(0)
                        }else{
                            setShowNext(false)
                        }
                        setShowDisclosure(true)
                        setAeroUrl(value.data.redirectUrl + getExtraQueryparam())
                    }else{
                        let fullRedirectUrl = value.data.redirectUrl + getExtraQueryparam()
                        window.open(fullRedirectUrl, '_self')
                    }
                } else {
                    toast.error(`${value.data.message}`)
                }
            }
        }).catch(reason => {
            console.log(reason)
            toast.error(`invalid OTP`)
            setIsLoading(false)
        }).finally(() => setIsLoading(false))
    }


    function getExtraQueryparam() {
        let val = localStorage.getItem(QUERY_PARAM).toString()
        val = '&' + val?.replace(/^"(.*)"$/, '$1');
        return val
    }



    function sendToptSMS() {
        setIsLoadingResed(true)
        const { tempToken } = data;
        flowBackEndServices2.sendTOpt(null, tempToken).then(value => {
            if (value?.status === 200) {
                setOtp('')
                toast.success(`OTP sent`)
                showResend();
            }
        }).catch(reason => {
            toast.error(`Failed`)
            setIsLoadingResed(false)
        }).finally(() => setIsLoadingResed(false))

    }




    function loginWithOTP() {
        setIsLoadingResed(true)
        const { tempToken } = data;
        flowBackEndServices2.sendTOpt(null, tempToken).then(value => {
            if (value?.status === 200) {
                setOtp('')
                setOtpType('otp')
                toast.success(`OTP sent`)
            }
        }).catch(reason => {
            toast.error(`Failed`)
            setIsLoadingResed(false)
        }).finally(() => setIsLoadingResed(false))
    }

    function scopeParse(scope, scopeString) {
        var permission;

        switch (scope) {
            case "profile.read":
                permission = "Read profile details"
                break;
            case "orders.read":
                if (scopeString.indexOf("orders.write") !== -1) {
                    break;
                }
            case "orders.write":
                permission = "Place & manage orders"
                break;
            case "holdings.read":
                permission = "Fetch holdings"
                break;
            default:
                permission = ""
                break;
        }
        return permission
    }

    function setUpSessionData(value,dispatch,data) {
        let userData = value.data
        if(userData.status === 'success'){
            console.log("setUpSessionData", userData)
            let newData = {...data,refreshToken: userData.refreshToken,mfaType: userData.data.mfa_type,mfaEnabled: userData.mfaEnabled}
            let dataSet = {data: newData,user_id: data.clientCode}
            dispatch(setOtpData(dataSet))
            toast.success(`OTP Verified`)
            const refreshData = { refreshToken: userData.refreshToken,ssoToken: userData.ssoToken,mfaType:userData.mfa_type,deviceId: data.deviceId,tradingActive: userData.tradingActive}
            const userModify = { ...userData.data,...refreshData}
    
            // login event
            dispatch(setEmailAndMobile({mobile: null,email: userModify.email }))
            dispatch(loginEvent())
    
            authService.saveUserSessionFlow(userModify,refreshData,dispatch)
            if(userData.data.kra_status=="HOLD"){
                setShowNext(true)
                setKra(1)

            }else if(userData.data.kra_status=="REJECTED"){
                setShowNext(true)
                setKra(0)
            }else{
                setShowNext(false)
            }
            setShowDisclosure(true)
            // if(mfaType === 'otp') navigate("/setTOtp",{ replace: true})
            // if(mfaType === 'totp')  navigate("/tvTerminal",{ replace: true});
        }
    }

    function acknowledge(){
        localStorage.setItem("acknowledge_disclosure","false")
        sessionStorage.setItem("acknowledge_disclosure","false")
        if(aeroUrl){
            window.open(aeroUrl, '_self')
        }else{
            if(otpType === 'otp') navigate("/setTOtp",{ replace: true})
            if(otpType === 'totp')  navigate("/tvTerminal",{ replace: true});
        }
    }

    function getDisclosure(){
        localStorage.setItem("acknowledge_disclosure","true")
        sessionStorage.setItem("acknowledge_disclosure","true") //To block back from browser
        console.log("isDesktop",isDesktop)
        if(isDesktop){
            return(
                <React.Fragment>
                    <div className='disclosureLayer'></div>
                    <div className='disclosure'>
                    {showNext && <KraStatus kraStatus={kra}/>}
                            {!showNext && <RiskDisclosure />}
                            <div className="dot-container">
                                <div id="first-dot" className="cr-dot"></div>
                                <div id="second-dot" className="cr-dot"></div>
                            </div>
                        {!showNext? <div className='discFooter'>
                            <div className='ackButton c-pointer' onClick={acknowledge}>I understand</div>
                        </div>:<div className='discFooter'>
                            <div className='ackButton c-pointer' onClick={moveToNext}>Next</div>
                        </div>}
                    </div>
                </React.Fragment>
            )
        }else{
            return(
                <React.Fragment>
                    <div className='disclosureLayer'></div>
                    <div className='disclosure' style={{"width":"300px"}}>
                    {showNext && <KraStatus kraStatus={kra}/>}
                            {!showNext && <RiskDisclosure />}
                            <div className="dot-container">
                                <div id="first-dot" className="cr-dot"></div>
                                <div id="second-dot" className="cr-dot"></div>
                            </div>
                        {!showNext? <div className='discFooter'>
                            <div className='ackButton c-pointer' onClick={acknowledge}>I understand</div>
                        </div>:<div className='discFooter'>
                            <div className='ackButton c-pointer' onClick={moveToNext}>Next</div>
                        </div>}
                    </div>
                </React.Fragment>
            )
        }

    }

    function Scope() {
        var scopeString = themeData.scope
        var scopeArray = scopeString.split(",");
        return (
            <div className='permission_box' style={{width:"100%"}}>
                <div style={{ fontSize: '13px', alignItems: 'flex-start', fontWeight: 'bold' }} className="colorGrey pb-3">
                    You are about to grant the following permissions to {themeData.name}:
                    {scopeArray.map(scope => {
                        var parsedScope = scopeParse(scope, scopeString)
                        if (parsedScope && parsedScope != "") {
                            return (<li className='permission-list-item' style={{fontWeight: 'normal'}}>
                                {parsedScope}
                            </li>)
                        }
                    })}

                </div>
                <label style={{ fontSize: '13px', alignItems: 'flex-start' }} className="colorGrey pb-3 ">
                    <input
                        type="checkbox"
                        defaultChecked={false}
                        value={isPermissionGranted}
                        onChange={setPermission}
                        style={{ marginRight: '3px'}}
                    />
                    I agree to give my account access to {themeData.name}
                </label>
            </div>


        )
    }

    return (
        <React.Fragment>
        <Background>

            <div className="h-100 d-flex card_padding flex-column justify-content-between align-items-center">

                <div className="py-2 align-self-start">
                    <Link to="/login"><i className="fas fa-arrow-left fas fa-lg" /></Link>
                </div>

                <div className="d-flex pb-3 pr-3 pl-3 flex-column justify-content-center align-items-center" style={{width:"100%"}}>


                    <h3 className="heading1 py-1 align-self-start">{otpType?.toUpperCase()} Verification</h3>



                    {otpType === 'otp' ? (
                        <div style={{ fontSize: '13px' }} className="colorGrey align-self-start pb-3">We have sent an OTP on your mobile & email. <br />Please enter the OTP here to complete the verification</div>
                    ) : (
                        <div style={{ fontSize: '13px' }} className="colorGrey align-self-start pb-3">Use your authenticator app to generate 6-digit <br />TOTP and enter same here.</div>
                    )}



                        <OtpInput
                            inputStyle="opt_box"
                            value={Otp}
                            onChange={otp => {
                                setOtp(otp);
                            }}
                            numInputs={6}
                            isInputNum={true}
                            shouldAutoFocus={true}
                            separator={<span />}
                        />

                    {(themeData && themeData.permission_needed) ? Scope() : ("")}


                    {otpType === 'otp' && (
                        <div className="colorBlue align-self-start py-1">

                            {showResetBtn ? <div className="d-flex flex-row c-pointer">
                                {isLoadingResed ? (
                                    <Spinner className="mr-2" as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                ) : <span className='4' onClick={resendPassword} style={{ display: 'flex', alignItems: 'center' }}><img src={refresh} alt={''} />Resend OTP</span>}
                            </div>
                                : <div className="d-flex flex-row justify-content-start align-items-center">
                                    <div className="colorGrey">OTP can be resent in:</div>
                                    <Timer initialSeconds={59} initialMinute={0} showResend={showResend} />
                                </div>}

                        </div>

                    )}

                    <Button disabled={isLoading || Otp.length !== 6 || (themeData.permission_needed  && !isPermissionGranted )} className="blue_btn w-100 my-3" onClick={handleOnClick} variant="primary" type="submit">
                        {isLoading && (
                            <Spinner className="mr-2" as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                        )}
                        Verify {otpType?.toUpperCase()} {themeData && themeData.permission_needed && ("& Grant permission")}
                    </Button>

                    {otpType === 'totp' && (
                        <div onClick={loginWithOTP} className="heading2 c-pointer align-self-start colorBlue">Login with OTP <span className="colorGrey">instead</span></div>
                    )}

                </div>


            </div>

        </Background>
        {showDisclosure && getDisclosure()}
        </React.Fragment>
    );

    function moveToNext(){
        setShowNext(false)
    }
}

export default loginOTPVerify;
